<template>
	<div>
		<Header2022 :navbg='true'></Header2022>
		<div class="content">
			<div class="banner" v-if="topArticle">
				<el-carousel height='504px' arrow='nerver' indicator-position='none'>
					<el-carousel-item v-for="(item,index) in topArticle" :key="index">
						<el-image :src="item.image" @click="articleTap(item)"></el-image>
						<div class="right" @click="articleTap(item)">
							<p class="title">{{item.title}}</p>
							<p class="desc">{{item.description}}</p>
							<div class="info">
								<span class="time">{{item.createtime}}</span>
								<span class="count">
									<img src="../../assets/images/icon-eye.svg">
									<i>{{item.views}}</i>
								</span>
							</div>
						</div>
					</el-carousel-item>
				</el-carousel>
			</div>
			<div class="media">
				<div class="wrapper">
					<div class="label" v-if="tabList && tabList.length>0">
						<div class="title">新闻中心</div>
						<div class="btnGroup">
							<el-button class="btn" :class="paramsName==item.params?'active':''" v-for="(item,index) in tabList" :key="index"
							 @click="newsTab(item)">
								{{item.name}}
							</el-button>
						</div>
					</div>
					<div class="list clearfix" id="J_list">
						<div class="news-list clearfix">
							<div class="item-wrap" v-for="(item,index) in newsList" :key="index" @click="articleTap(item)">
								<div class="tile-link">
									<div class="tile-media">
										<img :src="item.image">
									</div>
									<div class="tile-content-text">
										<div class="title-head btnGroup">
											<p class="tile-content-headline">{{item.title}}</p>
										</div>
										<div class="info clearfix">
											<span class="time">{{item.createtime}}</span>
											<span class="count">
												<img src="../../assets/images/icon-eye.svg">
												<i>{{item.views}}</i>
											</span>
										</div>
									</div>
									<div class="tile-subhead">{{item.description}}</div>
								</div>
							</div>
						</div>

						<div v-if="loading" class="tip">加载中...</div>
						<div v-if="noMore" class="tip">没有更多了</div>
					</div>

				</div>
			</div>
		</div>
		<Footer2022></Footer2022>
	</div>
</template>

<script>
	import Header2022 from '@/components/header2022.vue'
	import Footer2022 from '@/components/footer2022.vue'
	import Banner from '@/components/banner.vue'
	import utils from '@/common/utils'
	import {
		mapState
	} from 'vuex'
	export default {
		data() {
			return {
				tabList: [],
				newsList: [],
				page: 1,
				noMore: false,
				loading: false,
				channel: null, //所有分类集合
				topArticle: null,
			}
		},
		components: {
			Banner,
			Header2022,
			Footer2022,
		},
		computed: {
			...mapState(['newsApi', 'ocApi']),
			paramsName: function() {
				if (this.$route.name == "newsList" && this.$route.params.paramsName) {
					let paramsName = this.$route.params.paramsName
					this.channel=this.$route.params.paramsName
					return paramsName;
				} else {
					return ''
				}
			},
			disabled: function() {
				return this.loading || this.noMore
			},
		},
		watch: {
			paramsName: {
				handler: function(e) {
					this.newsList = []
					this.page = 1
					this.noMore = false
					this.loading = false
					this.getArticleList('');
				},
			}
		},
		created: function() {
			window.addEventListener('scroll', this.onScroll);
			this.getNewsClass();
		},
		methods: {
			onScroll() {
				let that = this;
				if (that.disabled) {
					return;
				}
				//可滚动容器的高度
				let innerHeight = document.querySelector('#app').clientHeight;
				//屏幕尺寸高度
				let outerHeight = document.documentElement.clientHeight;
				//可滚动容器超出当前窗口显示范围的高度
				let scrollTop = document.documentElement.scrollTop;
				//scrollTop在页面为滚动时为0，开始滚动后，慢慢增加，滚动到页面底部时，出现innerHeight < (outerHeight + scrollTop)的情况，严格来讲，是接近底部。
				// console.log(scrollTop)
				if (innerHeight <= (outerHeight + scrollTop)) {
					//加载更多操作
					that.page = that.page + 1;
					that.getArticleList('');
				}
			},
			newsTab: utils.debounce(function(e) {
				this.channel = e.params;
				this.$router.push({
					name: e.pathName,
					params: {
						paramsName: e.params
					}
				});
			}, 500, true),
			getNewsClass() {
				let that = this;
				that.$axios.get(that.ocApi + 'appmanage/dictionary', {
						params: {
							code: 'web_news'
						}
					})
					.then(res => {
						let data = res.data.data;
						let dataArr = [];
						for (const item of data) {
							dataArr.push(item.value);
						}
						that.channel = dataArr.join(',');

						let newArr = data.map(iterator => {
							return {
								name: iterator.name,
								pathName: 'newsList',
								params: iterator.value,
							}
						});
						let origin = [{
							name: '全部',
							pathName: 'newsList',
							params: dataArr.join(','),
						}]
						that.tabList = origin.concat(newArr)
						that.getArticleList('top');
						that.getArticleList('');
					})
			},
			getArticleList: function(flag) {
				let that = this;
				that.loading = true
				that.$axios.post(that.newsApi + 'index/index', {
						channel: that.channel,
						page: that.page,
						flag
					})
					.then(res => {
						if (res.data.code == 1) {
							if (flag == 'top') {
								that.topArticle = res.data.data.data.slice(0, 3);
							} else {
								if (res.data.data.current_page >= res.data.data.last_page) {
									that.noMore = true
								}
								that.newsList.push(...res.data.data.data);
							}
							that.loading = false;
						} else {
							that.$message.error(res.data.msg);
						}
					})
			},
			articleTap: utils.debounce(function(e) {
				this.$router.push({
					name: 'newsDetail',
					params: {
						id: e.id,
						paramsName: this.channel
					}
				});
			}, 500, true),
		},
	}
</script>

<style lang="scss" scoped>
	.content {
		background: #f2f2f2;
		padding-top: 40px;
		box-sizing: border-box;
	}

	.btnGroup {
		font-size: 0;
	}

	.btnGroup .btn:not(:last-child) {
		margin-right: 10px;
	}

	.btn {
		display: inline-block;
		font-size: 12px;
		padding: 10px 15px;
		overflow: hidden;
		position: relative;
		font-weight: 400;
	}

	.btn.active {
		color: #fff;
		background: #00d596;
		border: 1px solid #00d596;
	}

	.info {
		bottom: 40px;
		font-size: 12px;
	}

	.info .time {
		float: left;
	}

	.info .count {
		float: right;
	}

	.info .count img {
		height: 10px;
		margin-right: 5px;
	}

	.info .count i {
		font-style: normal;
	}

	#J_list {
		min-height: 200px;
	}

	.media {
		overflow: hidden;
		background: #f2f2f2;
	}

	.banner {
		width: 1200px;
		margin: 64px auto 0;

		.el-carousel__item {
			display: flex;

			.el-carousel__container {}

			.el-image {
				width: 768px;
				height: 100%;
			}

			.right {
				width: 432px;
				background: #FFFFFF;
				box-sizing: border-box;
				padding: 83px 60px 61px 61px;

				.title {
					font-size: 26px;
					color: #666666;
					line-height: 38px;
					
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;
					overflow: hidden;
					word-wrap: break-word;
				}

				.desc {
					font-size: 14px;
					color: #666666;
					line-height: 27px;
					margin-top: 32px;
					
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 6;
					overflow: hidden;
					word-wrap: break-word;
				}

				.info {
					display: flex;
					justify-content: space-between;
					margin-top: 84px;

					.time {
						font-size: 12px;
						color: #666666;
						line-height: 23px;
					}

					.count {
						img {
							width: 13px;
							height: 10px;
						}

						i {
							font-size: 12px;
							color: #666666;
							line-height: 23px;
							margin-left: 5px;
						}
					}
				}
			}
		}
	}

	.media>.wrapper {
		width: 1200px;
		margin: 0 auto;
		padding: 0px 0 48px;
	}

	.media>.wrapper .label {
		padding: 33px 40px 31px;
		margin-top: 40px;
		background: #fff;
	}

	.media>.wrapper .label .title {
		font-size: 16px;
		color: #303233;
		line-height: 16px;
	}


	.media>.wrapper .label p {
		margin-bottom: 4px;
	}

	.media>.wrapper .label .btn {
		margin-top: 16px;
		cursor: pointer;
	}

	.media>.wrapper .list {
		margin-top: 20px;
	}

	.media>.wrapper .list .item-wrap {
		background: #fff;
		width: 384px;
		float: left;
		margin-bottom: 20px;
		position: relative;
	}

	.media>.wrapper .list .item-wrap:not(:nth-child(3n)) {
		margin-right: 24px;
	}

	.media>.wrapper .list .item-wrap .tile-link {
		background: #fff;
		width: 100%;
	}

	.media>.wrapper .list .item-wrap .tile-link .tile-media img {
		display: block;
		width: 384px;
		height: 252px;
	}

	.media>.wrapper .list .item-wrap .tile-link .tile-content-text {
		font-size: 0;
		padding: 26px 20px 24px;
		-webkit-box-sizing: border-box;
		-moz-box-sizing: border-box;
		box-sizing: border-box;
		display: -ms-flexbox;
		display: -webkit-box;
		display: -moz-box;
		display: flex;
		-ms-flex: 1 1 auto;
		-webkit-box-flex: 1;
		-moz-box-flex: 1;
		flex: 1 1 auto;
		-ms-flex-direction: column;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		flex-direction: column;
		-ms-flex-pack: justify;
		-webkit-box-pack: justify;
		-moz-box-pack: justify;
		justify-content: space-between;
	}

	.media>.wrapper .list .item-wrap .tile-link .tile-content-headline {
		font-size: 18px;
		line-height: 26px;
		height: 50px;
		font-weight: 600;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		overflow: hidden;
		word-wrap: break-word;
	}

	.media>.wrapper .list .item-wrap .tile-link .info {
		padding-top: 24px;
	}

	.tile-link {
		overflow: hidden;
	}

	.tile-media,
	.tile-media img {
		width: 100%;
		-webkit-transition: -webkit-transform 250ms ease;
		transition: -webkit-transform 250ms ease;
		-moz-transition: transform 250ms ease, -moz-transform 250ms ease;
		transition: transform 250ms ease;
		transition: transform 250ms ease, -webkit-transform 250ms ease,
			-moz-transform 250ms ease;
		position: relative;
	}

	.tile-media:after {
		background-color: transparent;
		background-color: transparent;
		-webkit-transition: background-color 250ms ease;
		-moz-transition: background-color 250ms ease;
		transition: background-color 250ms ease;
	}

	.tile-link:hover .tile-media:after {
		content: '';
		display: block;
		position: absolute;
		height: 100%;
		width: 100%;
		top: 0;
		left: 0;
		background-color: rgba(0, 0, 0, 0.15);
	}

	.tile-link:hover .tile-media img {
		-webkit-transform: scale(1.05);
		-moz-transform: scale(1.05);
		-ms-transform: scale(1.05);
		transform: scale(1.05);
	}

	.tile-content-text {
		background-color: #fff;
		-webkit-transition: -webkit-transform 250ms ease;
		transition: -webkit-transform 250ms ease;
		-moz-transition: transform 250ms ease, -moz-transform 250ms ease;
		transition: transform 250ms ease;
		transition: transform 250ms ease, -webkit-transform 250ms ease,
			-moz-transform 250ms ease;
	}

	.list .tile-link:hover .tile-content-text {
		-webkit-transform: translate(0, -56px);
		-moz-transform: translate(0, -56px);
		-ms-transform: translate(0, -56px);
		transform: translate(0, -56px);
	}

	.tile-subhead {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 0;
		opacity: 0;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		margin: 20px;
		display: -webkit-box;
		font-size: 12px;
		word-wrap: break-word;
		line-height: 20px;
	}

	.list .tile-link {
		display: -webkit-box;
		display: -moz-box;
		display: -ms-flexbox;
		display: flex;
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-moz-box-orient: vertical;
		-moz-box-direction: normal;
		-ms-flex-direction: column;
		flex-direction: column;
	}

	.list .tile-link:hover .tile-subhead {
		-webkit-transition: opacity 180ms ease 180ms;
		-moz-transition: opacity 180ms ease 180ms;
		transition: opacity 180ms ease 180ms;
		opacity: 1;
		height: 3em;
	}

	.tip {
		padding: 20px 0px;
		text-align: center;
		color: rgba(51, 51, 51, 0.4);
		font-size: 16px;
	}
</style>
